import React from "react";
import logo from "../../../../assets/img/SPH_CDV1/logo.png";
import Obamacare from "../../../../assets/img/SPH_CDV1/Obamacare-Logo.png";
import Rolling from "../../../../assets/img/SPH_CDV1/Rolling.gif";
import calificarparasubsidios from "../../../../assets/img/calificarparasubsidios.png";
import subsidiosparasalud from "../../../../assets/img/subsidiosparasalud.png";
import subsidiosdesalud from "../../../../assets/img/subsidiosdesalud.png";
const Loader = ({loader,setLoader}) =>{
    let domainLogo;
    const domainName = window.location.hostname;
    if (domainName === 'dev.calificarparasubsidios.com'
        || domainName === 'pre.calificarparasubsidios.com' || domainName === 'calificarparasubsidios.com') {
        domainLogo = calificarparasubsidios;
    } else if
        (domainName === 'dev.subsidiosparasalud.com' || domainName === 'pre.subsidiosparasalud.com' || domainName === 'subsidiosparasalud.com') {
        domainLogo = subsidiosparasalud;
    } else if
        (domainName === 'dev.subsidiosdesalud.com' || domainName === 'pre.subsidiosdesalud.com' || domainName === 'subsidiosdesalud.com') {
        domainLogo = subsidiosdesalud;
    } else {
        domainLogo = logo;
    }
    return(
        <div className={`step-loading ${loader} `} id="myDiv">
        <div className="content">
            <div className="logo">
                <img src={domainLogo} alt="" />
            </div>
            <div className="resolver-logo">
                <img src={Obamacare} alt="" />
            </div>
            <h5 className="text-center">Analizando...</h5>
            <div className="loader_rolling">
                <img src={Rolling} alt="" />
            </div>
        </div>
    </div>
    )
}
export default Loader;