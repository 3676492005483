import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import { RedirectProvider } from "./Contexts/RedirectContext";
import Unqualified from "./Components/Pages/Unqualified";
import Thankyou from "./Components/Pages/Thankyou";
import NotFound from "./Components/Pages/NotFound";
import PdfView from"./Components/Pages/PdfView";
import Privacy_policy from "./Components/Includes/Layouts/Common/Privacy_policy";
import Terms_of_use from "./Components/Includes/Layouts/Common/Terms_of_use";
import Cookies_policy from "./Components/Includes/Layouts/Common/Cookies_policy";
import SPH_CDV2 from "./Components/Pages/SPH_CDV2/SPH_CDV2";
import SPH_CDV1 from "./Components/Pages/SPH_CDV1/SPH_CDV1";
import SPH_CDV2_1 from "./Components/Pages/SPH_CDV2_1/SPH_CDV2_1";
import SPH_CDV2_2 from "./Components/Pages/SPH_CDV2_2/SPH_CDV2_2";
import AdtopiaLoadValue from "./Components/Pages/AdtopiaLoadValue";
import Partners_list from "./Components/Pages/Partners_list/Partners_list";
import SPH_CDV3 from "./Components/Pages/SPH_CDV3/SPH_CDV3";
import SPH_CDV6B_MS from "./Components/Pages/SPH_CDV6B_MS/SPH_CDV6B_MS";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";
import SPH_CDV4 from "./Components/Pages/SPH_CDV4/SPH_CDV4";
import SPH_CDV8_4_1 from "./Components/Pages/SPH_CDV8_4_1/SPH_CDV8_4_1";
import SPH_CDVS31 from "./Components/Pages/SPH_CDVS31/SPH_CDVS31";
import GreencardThankyou from "./Components/Pages/GreencardThankyou";
import MedicareThankYou from "./Components/Pages/MedicareThankYou";
import SPH_CDV_S10 from "./Components/Pages/SPH_CDV_S10/SPH_CDV_S10";
import MedicareThankYouV2 from "./Components/Pages/MedicareThankYouV2";
import SPH_CDV_S11 from "./Components/Pages/SPH_CDV_S11/SPH_CDV_S11";
import CPS_V2 from "./Components/Pages/CPS_V2/CPS_V2";
import HelmetBasedOnDomain from "./Components/Includes/Layouts/Common/HelmetBasedOnDomain";


const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  // useEffect(() => {
  //   document.title = EnvConstants.AppTitle;
  // }, []);
  return (
    <>
      <HelmetBasedOnDomain />
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              <Route exact path={"/unqualified"} component={Unqualified} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={"/thankyou"} component={Thankyou} />
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={["/:enviroment/:project_id/pdf/:doc_type/:filename"]} component={PdfView}/>
              <Route exact path={["/Privacy-policy"]} component={Privacy_policy}/>
              <Route exact path={["/Terms-of-use"]} component={Terms_of_use}/>
              <Route exact path={["/Cookies-policy"]} component={Cookies_policy}/>
              <Route exact path={["/SPH_CDV2"]} component={SPH_CDV2}/>
              <Route exact path={["/SPH_CDV2_1"]} component={SPH_CDV2_1}/>
              <Route exact path={["/SPH_CDV2_2"]} component={SPH_CDV2_2}/>
              <Route exact path={["/partners-list"]} component={Partners_list}/>
              <Route exact path={["/SPH_CDV1"]} component={SPH_CDV1}></Route>
              <Route exact path={["/SPH_CDV3"]} component={SPH_CDV3}/>
              <Route exact path={["/SPH_CDV6B_MS"]} component={SPH_CDV6B_MS}/>
              <Route exact path={["/SPH_CDV4"]} component={SPH_CDV4}/>
              <Route exact path={["/SPH_CDV8_4_1"]} component={SPH_CDV8_4_1}/>
              <Route exact path={["/SPH_CDV_S3_1"]} component={SPH_CDVS31}/>
              <Route exact path={["/SPH_CDV_S10"]} component={SPH_CDV_S10}/>
              <Route exact path={["/SPH_CDV_S11"]} component={SPH_CDV_S11}/>
              <Route exact path={"/greencard-thankyou"} component={GreencardThankyou}/>
              <Route exact path={"/medicare-thankyou"} component={MedicareThankYou}/>
              <Route exact path="/value/:dynamicValue" component={AdtopiaLoadValue}/>
              <Route exact path={["/google","/testing"]} component={AdtopiaLoadDynamic}/>
              <Route exact path={"/medicare-thankyou-v2"} component={MedicareThankYouV2}/>
              <Route exact path={["/beneficiossalud02/CPS_V2"]} component={CPS_V2}/>

            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
