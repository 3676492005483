import { Helmet } from "react-helmet";
import faviconveronica from "../../../../assets/img/favicon.ico";
import calificarparasubsidios from "../../../../assets/img/calificarparasubsidios.png";
import subsidiosparasalud from "../../../../assets/img/subsidiosparasalud.png";
import subsidiosdesalud from "../../../../assets/img/subsidiosdesalud.png";


const HelmetBasedOnDomain = () => {
    const domainName = window.location.hostname;

    console.log("domainName",domainName)
    let favicon;
    let brandName;
    if( domainName === 'dev.consejosdeveronica.com' || domainName === 'pre.consejosdeveronica.com' || domainName === 'consejosdeveronica.com') {
        favicon = faviconveronica;
        brandName = 'consejosdeveronica.com';
    } else if
        (domainName === 'localhost' ||domainName === 'dev.calificarparasubsidios.com'
        || domainName === 'pre.calificarparasubsidios.com' || domainName === 'calificarparasubsidios.com') {
        favicon = calificarparasubsidios;
        brandName = 'calificar para subsidios';
    } else if
        (domainName === 'dev.subsidiosparasalud.com' || domainName === 'pre.subsidiosparasalud.com' || domainName === 'subsidiosparasalud.com') {
        favicon = subsidiosparasalud;
        brandName = 'subsidios para salud';
    } else if
        (domainName === 'dev.subsidiosdesalud.com' || domainName === 'pre.subsidiosdesalud.com' || domainName === 'subsidiosdesalud.com') {
        favicon = subsidiosdesalud;
        brandName = 'subsidios de salud';
    }
    else {
        console.log("elsee -------")
        favicon = faviconveronica;
        brandName ="consejosdeveronica.com"
    }
    return (
        <Helmet>
            <link rel="icon" href={favicon} />
            <title>{brandName}</title>
        </Helmet>
    )
}

export default HelmetBasedOnDomain;